// _base.scss imports _custom.scss and other partials.
@import "base";

// - normalize with small modifications for Drupal and AT: http://necolas.github.com/normalize.css/
// - grid widths, see $grid in _custom.scss
// - Default container alignment.
// - Flexible images and media declarations.
// - Copies of the SCSS mixins for reference, which includes an improved
//  element-invisible and focusable styles.

// =============================================================================
//  HTML5 display definitions
// =============================================================================

// apply a natural box layout model to all elements, but allowing components to change
html {
  box-sizing: border-box;
  height: 100%;
  overflow-y: scroll;
  // Touch / Tap Color
  -webkit-tap-highlight-color: $touch-tap-color;
}
*,
*::before,
*::after {
  box-sizing:inherit;
}

*::before,
*::after{
  // Icon fix on older mobile devices
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Corrects block display not defined in IE6/7/8/9 & FF3
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary,
picture {
  display: block;
}


// Corrects inline-block display not defined in IE6/7/8/9 & FF3
audio,
canvas,
video {
  display: inline-block;
}

iframe{
  border:0;
}

picture{
  line-height:0;
}

// Prevents modern browsers from displaying 'audio' without controls
// Remove excess height in iOS5 devices
audio:not([controls]) {
  display: none;
  height: 0;
}

// Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
// Known issue: no IE6 support
[hidden] {
  display: none;
}

// =============================================================================
//   Base
// =============================================================================

// 1 . Addresses margins handled incorrectly in IE6/7
body {
  min-height: 100%;
  margin: 0;
  padding: 0;
}

button,
input,
select,
textarea {
  // Addresses font-family inconsistency between 'textarea' and other form elements.
  font-family: $font-first;

  // 1. Corrects font size not being inherited in all browsers
  // 2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome
  // 3. Improves appearance and consistency in all browsers
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

// =============================================================================
//   Links
// =============================================================================

// Links
a {
  color:$link-color;
  text-decoration: underline;
  -webkit-tap-highlight-color: transparent; // no touch background color on mobile devices
  cursor:pointer; // fixex an extremly dumb iOS behavior - whitout this attribute some links not working correctly
  // This sucks at firefox..
  // &:visited{
  //  color:$link-color-visited;
  // }
  &:active,
  &.active{
    outline:0;
    color:$link-color-active;
    background-color:transparent;
  }
  &:hover,
  &:focus {
    color:$link-color-hover;
    text-decoration: underline;
    outline:0;
  }
  // Linked Images
  img{
    border:0 none;
  }
}

// =============================================================================
//  Typography
// =============================================================================

// Addresses styling not present in IE7/8/9, S5, Chrome
abbr[title] {
  border-bottom: 1px dotted;
}

blockquote {
  margin: 1em 40px;
}

// Addresses styling not present in S5, Chrome
dfn {
  font-style: italic;
}

// Addresses styling not present in IE6/7/8/9
mark {
  background: $info;
  color: $black;
}

// Corrects font family set oddly in IE6, S4/5, Chrome
// en.wikipedia.org/wiki/User:Davidgothberg/Test59
pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

// Improves readability of pre-formatted text in all browsers
pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: none;
  &::before{
    content: none;
  }
}

// =============================================================================
//   Lists
// =============================================================================

ul{
  list-style-type:square;
}

dl,
menu,
ol,
ul {
  margin: 1em 0;
}

dd {
  margin: 0 0 0 40px;
}

menu,
ol,
ul {
  padding: 0 0 0 40px;
}

nav ul,
nav ol {
  list-style: none;
  list-style-image: none;
}


// =============================================================================
//  Embedded content
// =============================================================================

// 1. Remove border when inside 'a' element in IE6/7/8/9, FF3
// 2. Improves image quality when scaled in IE7
// code.flickr.com/blog/2008/11/12/on-ui-quality-the-little-things-client-side-image-resizing/
img {
  -ms-interpolation-mode: bicubic;
}

// Corrects overflow displayed oddly in IE9
svg:not(:root) {
  overflow: hidden;
}

// =============================================================================
//  Figures
// =============================================================================

// Addresses margin not present in IE6/7/8/9, S5, O11
figure {
  margin: 0;
}


// =============================================================================
//  Forms
// =============================================================================

// Corrects margin displayed oddly in IE6/7
form {
  margin: 0;
}

// Define consistent margin, and padding
fieldset {
  margin: 0;
  padding: .35em .625em .75em;
}

// Placeholder
::placeholder{
  color: $placeholder-color;
}

// 1. Corrects color not being inherited in IE6/7/8/9
// 2. Corrects text not wrapping in FF3
// 3. Corrects alignment displayed oddly in IE6/7
legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

// Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
button,
input {
  line-height: normal;
}

// 1. Improves usability and consistency of cursor style between image-type 'input' and others
// 2. Corrects inability to style clickable 'input' types in iOS
// 3. Removes inner spacing in IE7 without affecting normal text inputs
// Known issue: inner spacing remains in IE6
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
}

// Re-set default cursor for disabled elements
button[disabled],
input[disabled] {
  cursor: default;
}

// 1. Addresses box sizing set to content-box in IE8/9
// 2. Removes excess padding in IE8/9
// 3. Removes excess padding in IE7
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="search"]{
  box-sizing: border-box;
  // Removes inner padding and search cancel button in S5, Chrome on OS X
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button{
    -webkit-appearance: none;
  }
}

// Removes inner padding and border in FF3+
// www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

// 1. Removes default vertical scrollbar in IE6/7/8/9
// 2. Improves readability and alignment in all browsers
textarea {
  overflow: auto;
  vertical-align: top;
}

// =============================================================================
//  Tables
// =============================================================================

@import 'libraries/bootstrap-sass/assets/stylesheets/bootstrap/tables';

// Fix Caption style
caption{
  color:$text-color;
}

// =============================================================================
//  Flexible Media and Cross browser improvements
// =============================================================================

// Ethan Marcotte - http://front.ie/l8rJaA
img,
embed,
object,
video {
  max-width: 100%;
}

// Disable flexiblity for IE8 and below
.lt-ie9{
  img,
  object,
  embed,
  video {
    max-width: none;
  }
}

// Override max-width 100% for map displays
#map img,
.gmap img,
.view-gmap img,
.openlayers-map img,
#getlocations_map_canvas img,
#locationmap_map img,
.geofieldMap img,
.views_horizontal_slider img {
  max-width: none !important;
}


// =============================================================================
//  Misc helpers, Accessibility classes etc
// =============================================================================

// Prevent overflowing content
header[role=banner],
.content-inner,
.nav,
.region-sidebar-first,
.region-sidebar-second,
.region-secondary-content,
.region-tertiary-content,
.region-footer {
  overflow: visible;
  word-wrap: break-word;
}


// General classes for hiding content.
.element-invisible {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.element-invisible.element-focusable:active,
.element-invisible.element-focusable:focus {
  clip: auto;
  height: auto;
  overflow: visible;
  position: static;
  width: auto;
}

// Hide content from all users
.element-hidden {
  display: none;
}
